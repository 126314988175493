import React from 'react';
import classes from './Spinner.module.scss';

const Spinner = ({ spinnerClasses }) => (
  <div className={`${classes.spinner} ${spinnerClasses} spinner`}>
    Loading...
  </div>
);

Spinner.defaultProps = {
  spinnerClasses: '',
};

export default Spinner;
