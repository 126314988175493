import { useState, useEffect } from 'react';

import http from '../services/http.service';
import { TOKEN_VERIFY_STATUS } from '../constans/tokenVerifyStatus';
import { API } from '../constans/http';
import {
  parseSearchParams,
  replaceStringSpacesByPlus,
} from '../helpers/helper-methods';

export const useVerifyToken = (apiUrl, location) => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [tokenVerifyStatus, setTokenVerifyStatus] = useState(
    TOKEN_VERIFY_STATUS.PENDING,
  );

  useEffect(() => {
    const params = parseSearchParams(location);

    if (params.token) {
      params.token = replaceStringSpacesByPlus(params.token);
      const { token } = params;
      http
        .get(apiUrl, { params: { token } })
        .then(() => {
          setLoading(false);
          setUserData(params);
          setTokenVerifyStatus(TOKEN_VERIFY_STATUS.DONE);
        })
        .catch(({ message }) => {
          setLoading(false);
          setError(message);
          setTokenVerifyStatus(TOKEN_VERIFY_STATUS.FAIL);
        });
    } else {
      setLoading(false);
      setError(API.ERRORS.INVALID_TOKEN);
      setTokenVerifyStatus(TOKEN_VERIFY_STATUS.FAIL);
    }
  }, [apiUrl, location]);

  return [userData, tokenVerifyStatus, error, setError, loading, setLoading];
};
