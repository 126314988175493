import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import http from '../../services/http.service';
import Expired from '../../assets/img/icons/ic-expired.svg';
import Verify from '../../assets/img/icons/ic-verified.svg';
import { useVerifyToken } from '../../hooks/useVerifyToken';
import { API } from '../../constans/http';
import { TOKEN_VERIFY_STATUS } from '../../constans/tokenVerifyStatus';
import classes from './index.module.scss';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import Spinner from '../../components/UI/Spinner/Spinner';
import StorageService from '../../services/storage.service';

const Verification = ({ location, text = 'Your email was verified!', apiUrl = API.EMAIL_VERIFY }) => {
  const pwdRedirect = location.state && location.state.pwdRedirect;
  const pwdExpired = location.state && location.state.pwdExpired;
  const [
    userData,
    tokenVerifyStatus,
    error,
    setError,
    loading,
    setLoading,
  ] = useVerifyToken(API.TOKEN_VERIFY, location);

  useEffect(() => {
    if (tokenVerifyStatus === TOKEN_VERIFY_STATUS.DONE) {
      http
        .put(apiUrl, { ...userData })
        .then((_res) => {
          StorageService().remove(LOCALSTORAGE_KEYS.REGUSER);
          setError('');
          setLoading(false);
        })
        .catch(({ message }) => {
          setError(message);
          setLoading(false);
        });
    }
  }, [setError, setLoading, tokenVerifyStatus, userData]);

  return (
    <div className="blue-grad-bg center-vertical-page-wrapper">
      <div
        className={`${classes.container} center-content-vertical-mobile container-bordered auth-wrapper`}
      >
        {loading ? (
          <Spinner spinnerClasses={classes.spinner} />
        ) : pwdExpired ? (
          <>
            <Expired className={classes.verifIcon} />
            <div className={`${classes.title} t-500`}>Expired link</div>
            <Link
              to="/resend-pwd-link"
              className={`${classes.submitBtn} btn btn_md btn-uppercased t-600 btn_block btn btn_common`}
            >
              Resend link
            </Link>
          </>
        ) : pwdRedirect ? (
          <>
            <Expired className={classes.verifIcon} />
            <div className={`${classes.title} t-500`}>Unverified email</div>
            <Link
              to="/resend-email-link"
              className={`${classes.submitBtn} btn btn_md btn-uppercased t-600 btn_block btn btn_common`}
            >
              Resend link
            </Link>
          </>
        ) : !error ? (
          <>
            <Verify className={classes.verifIcon} />
            <div className={`${classes.title} t-500`}>
              {text}
            </div>
            <Link
              to="/signin"
              className={`${classes.submitBtn} btn btn_md btn-uppercased t-600 btn_block btn btn_common`}
            >
              Go to Login
            </Link>
          </>
        ) : (
          <>
            <Expired className={classes.verifIcon} />
            <div className={`${classes.title} t-500`}>Your link expired!</div>
            <Link
              to="/resend-email-link"
              className={`${classes.submitBtn} btn btn_md btn-uppercased t-600 btn_block btn btn_common`}
            >
              Resend link
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Verification;
